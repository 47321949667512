import React, { useState, useEffect } from "react"

export default function App() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  })
  const [isScrolled, setIsScrolled] = useState(false)
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50)
    }
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  const services = [
    {
      _id: "1",
      name: "Обслуживание арендатора",
      description:
        "Выставление счетов и закрывающих документов, контроль оплаты, начисление пени за задержку платежа, юридическое оформление изменений к договору, контроль за ежегодным повышением арендной ставки.",
      price: "3000 руб. в месяц",
      icon: "📊",
    },
    {
      _id: "2",
      name: "Налоговая и бухгалтерская отчетность ОСНО",
      description:
        "Налоговая и бухгалтерская отчетность на Основной системе налогообложения, включая управленческие отчеты для руководства.",
      price: "3000 руб. в месяц",
      icon: "📑",
    },
    {
      _id: "3",
      name: "Налоговая и бухгалтерская отчетность УСН",
      description:
        "Налоговая и бухгалтерская отчетность на Упрощенной системе налогообложения, включая управленческие отчеты для руководства.",
      price: "2000 руб. в месяц",
      icon: "💼",
    },
    {
      _id: "4",
      name: "Кадровое сопровождение одного сотрудника",
      description: "Оформление, расчет заработной платы и прочих начислений, отчетность в фонды.",
      price: "2500 руб. в месяц",
      icon: "👥",
    },
  ]

  const handleServiceClick = (serviceName) => {
    setFormData({
      ...formData,
      message: `Добрый день, меня интересует услуга ${serviceName}`,
    })
    const contactSection = document.getElementById("контакты")
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: "smooth" })
    }
  }

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    // Implement your form submission logic here
    console.log("Form submitted:", formData)
    alert("Сообщение отправлено!")
  }

  return (
    
    <div className="bg-white text-gray-900">
      <header className={`fixed w-full z-10 transition-all duration-300 ${isScrolled ? 'bg-white shadow-md' : 'bg-transparent'}`}>
        <div className="container mx-auto px-4 py-4 flex justify-between items-center">
          <h1 className={`text-2xl font-bold ${isScrolled ? 'text-gray-900' : 'text-white'}`}>BuhRent</h1>
          <nav className="hidden md:block">
            <ul className="flex space-x-6">
              {['О нас', 'Услуги', 'Контакты'].map((item) => (
                <li key={item}>
                  <a href={`#${item.toLowerCase()}`} className={`text-sm font-medium ${isScrolled ? 'text-gray-900 hover:text-blue-600' : 'text-white hover:text-gray-200'}`}>
                    {item}
                  </a>
                </li>
              ))}
            </ul>
          </nav>
          <button 
            className="md:hidden text-white"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </button>
        </div>
        {isMenuOpen && (
          <div className="md:hidden bg-white">
            <ul className="px-4 py-2">
              {['О нас', 'Услуги', 'Контакты'].map((item) => (
                <li key={item} className="py-2">
                  <a 
                    href={`#${item.toLowerCase()}`} 
                    className="text-gray-900 hover:text-blue-600"
                    onClick={() => setIsMenuOpen(false)}
                  >
                    {item}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        )}
      </header>
      <main>
        <section className="relative min-h-screen flex items-center justify-center bg-gradient-to-r from-blue-500 to-purple-600 text-white">
          <div className="absolute inset-0 bg-black opacity-50"></div>
          <div className="relative z-10 text-center px-4 sm:px-6 lg:px-8">
            <h2 className="text-4xl sm:text-5xl md:text-6xl font-bold mb-6">Профессиональное сопровождение арендного бизнеса</h2>
            <p className="text-lg sm:text-xl md:text-2xl mb-8 max-w-3xl mx-auto">
              Доверьте ваш бухгалтерский и налоговый учет эксперту с 20-летним стажем. 
              Действительный член Института профессиональных бухгалтеров РФ на страже вашего бизнеса.
            </p>
            <button
              className="bg-white text-blue-600 px-6 sm:px-8 py-3 sm:py-4 rounded-full text-base sm:text-lg font-semibold transition-colors hover:bg-gray-100"
              onClick={() => {
                const contactSection = document.getElementById("контакты")
                if (contactSection) {
                  contactSection.scrollIntoView({ behavior: "smooth" })
                }
              }}
            >
              ПОЛУЧИТЬ КОНСУЛЬТАЦИЮ
            </button>
          </div>
        </section>
        <section id="о-нас" className="py-16 sm:py-20">
          <div className="container mx-auto px-4 sm:px-6 lg:px-8">
            <h2 className="text-3xl sm:text-4xl font-bold text-center mb-10 sm:mb-12">Наша экспертность</h2>
            <div className="space-y-6 text-base sm:text-lg max-w-3xl mx-auto">
              <p>
                Мы предлагаем комплексное решение для вашего арендного бизнеса: от ведения бухгалтерии 
                и кадрового учета до налогового планирования и юридического сопровождения. Наша команда 
                обеспечивает профессиональное управление всеми финансовыми аспектами вашего бизнеса.
              </p>
              <p>
                Выбирая нас, вы получаете не просто услугу, а надежного партнера в развитии вашего бизнеса. 
                Наша команда опытных бухгалтеров и юристов гарантирует эффективность и прозрачность всех процессов, 
                позволяя вам сосредоточиться на росте и развитии вашего бизнеса.
              </p>
              <p className="font-semibold">Почему клиенты выбирают нас:</p>
              <ul className="list-none space-y-4">
                {[
                  { title: "Экономия времени и ресурсов", description: "Освободите себя от рутинных задач бухгалтерии и сосредоточьтесь на стратегическом развитии бизнеса." },
                  { title: "Минимизация финансовых рисков", description: "Гарантия точности и своевременности всей финансовой отчетности, исключающая штрафы и санкции." },
                  { title: "Персонализированный подход", description: "Разработка индивидуальных решений, учитывающих уникальные особенности и потребности вашего бизнеса." },
                  { title: "Комплексное обслуживание", description: "Единый центр ответственности за все аспекты бухгалтерского и налогового учета вашего бизнеса." },
                ].map((item, index) => (
                  <li key={index} className="flex items-start">
                    <span className="flex-shrink-0 w-8 h-8 sm:w-10 sm:h-10 rounded-full bg-blue-100 text-blue-600 flex items-center justify-center mr-3 sm:mr-4 text-sm sm:text-base">
                      {index + 1}
                    </span>
                    <div>
                      <h3 className="font-semibold">{item.title}</h3>
                      <p className="text-gray-600">{item.description}</p>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </section>
        <section id="услуги" className="py-16 sm:py-20 bg-gray-100">
          <div className="container mx-auto px-4 sm:px-6 lg:px-8">
            <h2 className="text-3xl sm:text-4xl font-bold text-center mb-10 sm:mb-12">Наши услуги</h2>
            <div className="grid gap-6 sm:gap-8 md:grid-cols-2 lg:grid-cols-4">
              {services.map((service) => (
                <div
                  key={service._id}
                  className="bg-white rounded-lg shadow-lg overflow-hidden transition-transform hover:scale-105 cursor-pointer"
                  onClick={() => handleServiceClick(service.name)}
                >
                  <div className="p-6">
                    <div className="text-3xl sm:text-4xl mb-4">{service.icon}</div>
                    <h3 className="text-lg sm:text-xl font-semibold mb-2">{service.name}</h3>
                    <p className="text-gray-600 mb-4 text-sm sm:text-base">{service.description}</p>
                    <p className="text-base sm:text-lg font-bold text-blue-600">{service.price}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
        <section id="контакты" className="py-16 sm:py-20">
          <div className="container mx-auto px-4 sm:px-6 lg:px-8">
            <h2 className="text-3xl sm:text-4xl font-bold text-center mb-10 sm:mb-12">Свяжитесь с нами</h2>
            <div className="grid md:grid-cols-2 gap-8 sm:gap-12">
              <div>
                <h3 className="text-xl sm:text-2xl font-semibold mb-4">Наши контакты</h3>
                <ul className="space-y-4">
                  <li className="flex items-start">
  <svg className="w-6 h-6 text-blue-600 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"></path>
  </svg>
  <span>Павлова Юлия Александровна</span>
</li>
                  <li className="flex items-start">
                    <svg className="w-6 h-6 text-blue-600 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"></path>
                    </svg>
                    <span>+7 (916) 215-91-88</span>
                  </li>
                  <li className="flex items-start">
                    <svg className="w-6 h-6 text-blue-600 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"></path>
                    </svg>
                    <span>info@buhrent.ru</span>
                  </li>
                  
                  {/* <li className="flex items-start">
                    <svg className="w-6 h-6 text-blue-600 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"></path>
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"></path>
                    </svg>
                    <span>г. Москва, ул. Примерная, д. 123, офис 456</span>
                  </li> */}
                </ul>
                <div className="mt-8">
                  <h4 className="text-lg sm:text-xl font-semibold mb-4">Режим работы:</h4>
                  <p>Пн-Пт: 9:00 - 18:00</p>
                  <p>Сб-Вс: Выходной</p>
                </div>
              </div>
              <div>
                <h3 className="text-xl sm:text-2xl font-semibold mb-4">Отправить сообщение</h3>
                <form onSubmit={handleSubmit} className="space-y-4">
                  <div>
                    <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">
                      Ваше имя
                    </label>
                    <input
                      id="name"
                      name="name"
                      type="text"
                      required
                      value={formData.name}
                      onChange={handleChange}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      placeholder="Иван Иванов"
                    />
                  </div>
                  <div>
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                      Email или номер телефона
                    </label>
                    <input
                      id="email"
                      name="email"
                      type="email"
                      required
                      value={formData.email}
                      onChange={handleChange}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      placeholder="ivan@example.com или +7 (999) 123-45-67"
                    />
                  </div>
                  <div>
                    <label htmlFor="message" className="block text-sm font-medium text-gray-700 mb-1">
                      Ваше сообщение
                    </label>
                    <textarea
                      id="message"
                      name="message"
                      rows={4}
                      required
                      value={formData.message}
                      onChange={handleChange}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      placeholder="Опишите ваш запрос или вопрос"
                    />
                  </div>
                  <button
                    type="submit"
                    className="w-full bg-blue-600 text-white py-2 sm:py-3 px-4 rounded-md text-base sm:text-lg font-semibold transition-colors hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                  >
                    Отправить сообщение
                  </button>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>
      <footer className="bg-gray-900 text-white py-8">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <p>&copy; 2024 BuhRent. Все права защищены. Ваш надежный партнер в мире бухгалтерии и налогов.</p>
        </div>
      </footer>
    </div>
  )
}